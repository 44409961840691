import { BillingCode, CodeSets, FormErrorsStrict, Payor } from '../types';
import { STRING_BOOLEAN_HASH } from './globals';
import { ChiroUpBaseCommon } from './stringConstants';

export type TabParamsType = {
  name: string;
  href: string;
  active: boolean;
  routePath?: string;
};

export type BillingCodePayorType = {
  ID?: number;
  billingCodeID: number;
  payorID: number;
  allowedAmount: string | number;
  modifier1: string;
  modifier2: string;
  modifier3: string;
  modifier4: string;
};

export type PayorListType = {
  [key: string]: any;
  ID?: number;
  billingCodeID?: number;
  payorIntPk?: number;
  payorId?: string;
  legalName?: string;
  allowedAmount?: string | number;
  modifier1?: string;
  modifier2?: string;
  modifier3?: string;
  modifier4?: string;
  payors?: BillingCodePayorType[];
};

export const FeatureTitle = 'Clinic Database';
export const FeaturePath = '/settings/database';

/**
 * It seems that the same code will need to be duplicated lots of places if
 * something like this isn't done. I know it is just an 'any', but we could
 * throw an error if the field does not exist.
 */
export const FieldValidators = {
  isValidPayorModifier: (modKey: string) => {
    return {
      function: {
        value: (values: any) => {
          let resp: string | false = false;
          const [field, ord] = (modKey || '').split(/[[\]]/) || ['bogus', '-1'],
            index = Number.parseInt(ord),
            refs = values?.defaultModifiers || [];

          if (index !== -1) {
            const val = values?.[field]?.[index] || '';
            if (val) {
              resp =
                refs.indexOf(val) === -1
                  ? false
                  : `Payor-specific modifier '${val}' duplicates a default modifier.`;
            }
          }
          return resp;
        },
      },
    };
  },
  phone: () => {
    return {
      function: {
        value: (values: any) => {
          const { phone } = values;
          if (
            phone?.[1] === '1' &&
            phone?.split(' ')?.[0]?.length === 6 &&
            phone?.length < 13
          ) {
            return 'Phone number must be 7 digits.';
          } else if (phone?.[1] === '1' && phone?.length < 13) {
            return 'Phone number must be 10 digits.';
          }
          return false;
        },
      },
    };
  },
  isRequired: (text: string) => {
    return { required: { message: `${text} is required.` } };
  },
  isCurrency: (key: string, text: string, required: string) => {
    return {
      function: {
        value: (values: any) => {
          const amount = values?.[key] ? `${values?.[key]}` : 0;
          if (required !== 'required' && !amount) return false;
          const [t1, t2] = (amount || '').split(/\./),
            num1 = t1?.replace(/\D/g, ''),
            num2 = t2?.replace(/\D/g, '');

          if (t1 === num1 && t2 === num2 && (t2 || '').length === 2) {
            return false;
          }
          return `${text} should be in the format 0.00 with no commas or dollar sign.`;
        },
      },
    };
  },
  stringMinMax: (key: string, text: string, min: number, max: number) => {
    return {
      function: {
        value: (values: any) => {
          const str = values?.[key];
          min = min || 0;

          if (!str && !min) return false; // if no min, then no error.
          if (str) {
            if (str.length >= min && str.length <= max) return false;
          }
          return `${text} needs to be between ${min} and ${max} characters in length.`;
        },
      },
    };
  },
};

export enum InstanceAccessLevel {
  none = '',
  read = 'r',
  readWrite = 'rw',
}

export enum InstanceTableNames {
  payors = 'Payor',
  billingCodes = 'BillingCode',
  noShowCancelFees = 'NoShowCancelFees',
  billingAdjustments = 'BillingAdjustments',
  billingProfiles = 'BillingProfiles',
  compensationRates = 'ClinicCompensationRate',
  caseTypes = 'ClinicCaseType',
}

export const pathToInstance = {
  payors: 'payors',
  'billing-codes': 'billingCodes',
  'no-show-cancel-fees': 'noShowCancelFees',
  'billing-adjustments': 'billingAdjustments',
  'billing-profiles': 'billingProfiles',
  'compensation-rates': 'compensationRates',
  'case-types': 'caseTypes',
};

export enum InstanceKeys {
  payors = 'payors',
  billingCodes = 'billing-codes',
  noShowCancelFees = 'no-show-cancel-fees',
  billingAdjustments = 'billing-adjustments',
  billingProfiles = 'billing-profiles',
  compensationRates = 'compensation-rates',
  caseTypes = 'case-types',
}

export enum InstanceTitles {
  payors = 'Payors',
  billingCodes = 'Billing Codes',
  noShowCancelFees = 'No-Show & Cancel Fees',
  billingAdjustments = 'Billing Discounts',
  billingProfiles = 'Billing Profiles',
  compensationRates = 'Compensation Rates',
  caseTypes = 'Custom Case Types',
}

/**
 * This builds the href for a tab.
 *
 * @param instance
 * @returns
 */
export const instanceHref = (instance: InstanceKeys) =>
  `${FeaturePath}/${instance}`;

/**
 * Convenience method. Normally the same as the href in a tab,
 * but may not always be the case.
 *
 * @param instance
 * @returns
 */
export const instancePathname = (instance: InstanceKeys) =>
  instanceHref(instance);

export type InstanceParams = {
  payors: Payor[];
};

export const InsurancePrograms = [
  { value: 'auto', text: 'Automobile Medical' },
  { value: 'bcbs', text: 'Blue Cross / Blue Shield' },
  { value: 'champus', text: 'Champus' },
  // { value: 'champva', text: 'Champva' },
  { value: 'commercial', text: 'Commercial Insurance Co.' },
  { value: 'dmo', text: 'Dental Maintenance Organization' },
  { value: 'disability', text: 'Disability' },
  { value: 'epo', text: 'Exclusive Provider Organization (EPO)' },
  // { value: 'feca', text: 'FECA' },
  { value: 'fep', text: 'Federal Employees Program' },
  // { value: 'geha', text: 'GEHA' },
  // { value: 'ghp', text: 'Group Health Plan (GHP)' },
  { value: 'hmo', text: 'Health Maintenance Organization' },
  {
    value: 'hmomedicare',
    text: 'Health Maintenance Organization (HMO) Medicare Risk',
  },
  // { value: 'hdhc', text: 'High Deductible Health Plan (HDHC)' },
  { value: 'ii', text: 'Indemnity Insurance' },
  { value: 'liability', text: 'Liability Medical' },
  { value: 'mcaid', text: 'Medicaid' },
  { value: 'mcarea', text: 'Medicare Part A' },
  { value: 'mcareb', text: 'Medicare Part B' },
  { value: 'unknown', text: 'Mutually Defined or Unknown' },
  // { value: 'mcarehmo', text: 'Medicare Risk HMO' },
  { value: 'pos', text: 'Point of Service (POS)' },
  { value: 'ppo', text: 'Preferred Provider Organizations (PPO)' },
  { value: 'titlev', text: 'Title V' },
  // { value: 'tricare', text: 'Tricare' },
  { value: 'va', text: 'Veteran Affairs Plan' },
  { value: 'wc', text: 'Workers Compensation' },
  { value: 'otherfed', text: 'Other Federal Program' },
  { value: 'other', text: 'Other Non-Federal Program' },
];

export const InsuranceProgramEdiCode = {
  other: '11',
  ppo: '12',
  pos: '13',
  epo: '14',
  ii: '15',
  hmomedicare: '16',
  dmo: '17',
  auto: 'AM',
  bcbs: 'BL',
  champus: 'CH',
  commercial: 'CI',
  disability: 'DS',
  fep: 'FI',
  hmo: 'HM',
  liability: 'LM',
  mcarea: 'MA',
  mcareb: 'MB',
  mcaid: 'MC',
  otherfed: 'OF',
  titlev: 'TV',
  va: 'VA',
  wc: 'WC',
  _unknown: 'ZZ',
};

export const itemToOptionText = (instanceKey: string, item: any) => {
  const valueNumber = Number(item.value);
  const valueDisplay =
    valueNumber % 1 === 0 ? valueNumber : valueNumber.toFixed(2);
  const infoDisplay =
    item.structure === '% Discount'
      ? `${valueDisplay}%`
      : ChiroUpBaseCommon.format.asMoney(item.value);
  switch (instanceKey) {
    case 'payors':
      return item.payorID
        ? `${item.legalName} - ${item.payorID}`
        : item.legalName;
    case 'billing-codes':
      return `${item.codeSet} - ${item.code} - ${item.description}`;
    case 'billing-adjustments':
      return `${item.name} - ${item.structure} - ${infoDisplay}`;
    default:
      return '';
  }
};

export const applyDatabaseSaveRules = ({
  instanceKey,
  item,
  clinicId,
}: {
  instanceKey: InstanceKeys | string;
  item: any;
  clinicId: number | undefined;
}) => {
  // Sanity checks we want on the front and backend.
  const errors: FormErrorsStrict = {
    form: [],
    fieldErrors: {},
  };

  if (!clinicId) {
    errors.form.push({ message: 'Clinic ID is required.' });
  }

  switch (instanceKey) {
    // Billing codes returns something in the form that useForm does on
    // the frontend. Was thinking of using the normal BusinessRuleItemType,
    // with a translator. For later: TODO.
    case 'billingCodes':
    case InstanceKeys.billingCodes: {
      const typedItem = item as BillingCode,
        message = 'Required.';
      if (!typedItem.code) {
        errors.fieldErrors.code = { message };
      }
      if (!typedItem.description) {
        errors.fieldErrors.description = {
          message,
        };
      }
      if (!typedItem.codeSet) {
        errors.fieldErrors.codeSset = { message };
      }
      if (
        typedItem.codeSet === CodeSets.SERVICE ||
        typedItem.codeSet === CodeSets.CPT
      ) {
        if (!typedItem.billedAmount) {
          errors.fieldErrors.billedAmount = {
            message,
          };
        }
      }

      const twoCharModifiers = 'Modifiers must be two characters.';

      const defaultModifiersByValue =
        typedItem.defaultModifiers?.reduce((a, mod) => {
          if (mod) {
            a[mod] = true;
          }
          return a;
        }, {} as STRING_BOOLEAN_HASH) ?? {};

      // Removes duplicates.
      typedItem.defaultModifiers = Object.keys(defaultModifiersByValue) ?? [];

      typedItem.defaultModifiers?.forEach((mod, index) => {
        if (mod) {
          if (mod.length !== 2) {
            errors.fieldErrors[`defaultModifiers[${index}]`] = {
              message: twoCharModifiers,
            };
          }
        }
      });
      if (
        typedItem.payors &&
        Array.isArray(typedItem.payors) &&
        typedItem.payors.length
      ) {
        typedItem.payors.forEach((payor, index) => {
          if (!payor.payorID) {
            errors.form.push({
              message: `Payor # ${index + 1} needs an id.`,
            });
          }
          // if (!payor.allowedAmount) {
          //   errors.fieldErrors[`payor[${index}].allowedAmount`] = {
          //     message: 'Allowed amount is required.',
          //   };
          // }
          if (Number(payor.allowedAmount) > Number(typedItem.billedAmount)) {
            errors.fieldErrors[`payor[${index}].allowedAmount`] = {
              message: 'Allowed amount cannot be more than billed amount.',
            };
          }
          let oops = [] as string[];
          const dupe = 'Duplicates a default modifier.';

          const usedPayorModifiers = {} as STRING_BOOLEAN_HASH,
            modifierKeys = ['modifier1', 'modifier2', 'modifier3', 'modifier4'];
          modifierKeys.forEach((mod) => {
            const anyPayor = payor as any;
            if (!anyPayor[mod]) return;
            usedPayorModifiers[anyPayor[mod]] = true;

            if (defaultModifiersByValue[anyPayor[mod] ?? '']) {
              oops.push(dupe);
            }
            if (anyPayor[mod] && anyPayor[mod].length !== 2) {
              oops.push(twoCharModifiers);
            }
            if (oops.length) {
              errors.fieldErrors[`payor[${index}].${mod}`] = {
                message: oops.join(' '),
              };
            }
            oops = [];
          });

          // This should remove any duplicate modifiers on a single payor.
          const usedModifierValues = Object.keys(usedPayorModifiers) || [];
          modifierKeys.forEach((mod, i) => {
            const anyPayor = payor as any;
            if (usedModifierValues[i]) {
              anyPayor[mod] = usedModifierValues[i];
            } else {
              delete anyPayor[mod];
            }
          });
        });
      }

      const fieldErrorKeys = Object.keys(errors.fieldErrors);
      if (fieldErrorKeys.length) {
        errors.form.push({ message: 'There are field errors.' });
      }
      break;
    }
    default:
      break;
  }
  return errors;
};

/**
 * This is in terms of the string that the user sees. It could change, so
 * having two methods for future expansion.
 *
 * @param instanceKey
 * @param item
 * @returns
 */
export const itemToDataDescription = (instanceKey: string, item: any) => {
  return itemToOptionText(instanceKey, item);
};
