/**
 * [2023-07-20.1007 by Brian]
 * Split up into sections.
 *
 * [2023-07-06.0740 by Brian]
 * Updated to a data structure that would work with Intellisense.
 *
 * [2023-07-05.1427 by Brian]
 * Created to store string constants that are used in multiple places.
 */

import dayjs, { Dayjs, isDayjs } from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { convertToMS } from '../functions';
import { ScheduleClinicSettings } from './ScheduleDefaults';

dayjs.extend(utc);
dayjs.extend(tz);

export const ChiroUpPatientCommon = {
  notFound: 'Patient not found.',
  notAuthorized: 'You are not authorized to access this patient.',
  insuranceNotFound: 'Patient insurance not found.',
};

export const ChiroUpLoginCommon = {
  invalidCode: 'The code is not correct, please try again.',
  emptyCode: 'The code field is required.',
  connectionFailed:
    'The connection to the authentication server could not be made. This may be temporary.',
  triesExhausted: 'You have exceeded the number of allowed attempts.',
};

export const ChiroUpSchedulingCommon = {
  notAuthorized: 'You are not authorized to access scheduling.',
  notAvailable: 'Scheduling is not available.',
  clinicPreferences: {
    save: {
      success: 'Schedulng preferences were saved.',
      error: 'Scheduling preferences could not be saved.',
    },
  },
  appFullName: 'HealthCom Scheduling',
};

export const ChiroUpTransactionCommon = {
  billingStarted: `A claim has already been submitted.
     Please exercise care when editing.
     Changes should only be made in response 
     to claim errors or feedback from the 
     payor to avoid potential discrepancies.`,
};

export const ChiroUpAppointmentCommon = {
  unableToReschedule: 'Unable to reschedule appointment.',
  notAuthorized: 'You are not authorized to access this appointment.',
  notFound: 'Appointment not found.',
  cancelFailed: 'The appointment could not be canceled.',
  cannotModify: 'This appointment may not be modified.',
  tooSoon: {
    code: 'TOO_SOON',
    message: 'Appointments must be scheduled more than two hours in advance.',
    friendly:
      'Appointments must be scheduled more than two hours in advance. Please select a different time.',
  },
  noPast: {
    code: 'NO_PAST',
    message: 'Appointments in the past are not supported.',
    friendly:
      'There appears to be a problem with the date. It appears to be in the past. Please try again.',
  },
  noSameDay: {
    code: 'NO_SAME_DAY',
    message: 'The clinic does not allow same-day appointments.',
    friendly:
      'The clinic does not allow same-day appointments. Please change the appointment to a different day.',
  },
  outsideCancelWindow: {
    code: 'TOO_LATE_TO_CANCEL',
    message: 'Cancellation period expired.',
    friendly: (minutes: number) =>
      `The clinic does not allow cancelling an appointment within ${ChiroUpAppointmentCommon.cancellationSlotByMinutes[minutes]} of the appointment time.`,
  },
  cancellationSlotByMinutes: ScheduleClinicSettings.cancellationOptions.reduce(
    (acc, obj) => {
      return { ...acc, [obj.value]: obj.text };
    },
    {},
  ) as { [key: number]: string },
};

const commonDisplayDatetime = (
  value?: Date | Dayjs | string | number,
  timezone?: string,
  format?: string,
) => {
  if (!value) {
    return '';
  }
  if (isDayjs(value)) {
    return value.format(format || ChiroUpDayJsCommon.format.datetime);
  }

  let formatted: string = '- bad-time -';
  let valueToUse = '';
  try {
    valueToUse = getValueToUse(value) as any;

    formatted = (
      dayjs.isDayjs(valueToUse)
        ? timezone
          ? valueToUse.tz(timezone)
          : valueToUse
        : timezone
          ? dayjs.tz(valueToUse, timezone)
          : dayjs(valueToUse)
    ).format(format || ChiroUpDayJsCommon.format.datetime);
  } catch (e) {
    console.error({
      value,
      valueToUse,
      e,
    });
  }

  return ChiroUpDayJsCommon.cleanupDatetime(formatted);
};

export const ChiroUpDayJsCommon = {
  defaultTimezone: 'America/Chicago',
  format: {
    date: 'MMM D, YYYY',
    isoDate: 'YYYY-MM-DD',
    mmddyyyy: 'MM/DD/YYYY',
    datetime: 'MMM D, YYYY [at] h:mma',
    timestamp: 'YYYY-MM-DD HH:mm:ss',
    time: 'h:mma',
  },
  now: (fmt?: string) =>
    dayjs().format(fmt ?? ChiroUpDayJsCommon.format.mmddyyyy),
  getAsciiTimestamp: (d?: Date | null | undefined) => {
    if (!d) {
      d = new Date();
    }
    return dayjs(d).format(ChiroUpDayJsCommon.format.timestamp);
  },
  getYyyyMmDd: (
    d: Date | null | undefined | dayjs.Dayjs,
    timezone?: string,
  ) => {
    if (!d) {
      d = new Date();
    } else if (!timezone && dayjs.isDayjs(d)) {
      return (d as dayjs.Dayjs).format(ChiroUpDayJsCommon.format.isoDate);
    }

    return dayjs(d).tz(timezone).format(ChiroUpDayJsCommon.format.isoDate);
  },
  getMmDdYyyy: (
    d: Date | null | undefined | dayjs.Dayjs,
    timezone?: string,
  ) => {
    const fmt = 'MM/DD/YYYY';
    if (!d) {
      d = new Date();
    } else if (!timezone && dayjs.isDayjs(d)) {
      return (d as dayjs.Dayjs).format(fmt);
    }

    return dayjs(d).tz(timezone).format(fmt);
  },
  cleanupDatetime: (str: string | undefined) =>
    str &&
    str
      // .replace(/(\d)am\b/g, '$1 a.m.')    // Just for fun, feel free to remove.
      // .replace(/(\d)pm\b/g, '$1 p.m.')    // Just for fun, feel free to remove.
      .replace(/ /g, '\u00A0') // This makes it a non-breaking space.
      .replace(/:00/g, ''), // This gets rid of the :00.
  display: {
    datetime: commonDisplayDatetime,
    datetimeWithTz: (
      value: string | number,
      objectTz: string | undefined,
      userTz: string | undefined,
      format?: string,
    ) => {
      let val = commonDisplayDatetime(value, objectTz, format);
      if (objectTz !== userTz) {
        val = `${val} (${objectTz})`;
      }
      return val;
    },
    date: (value?: Date | string | number, timezone?: string) => {
      if (!value) {
        return '';
      }

      const valueToUse = getValueToUse(value),
        formatted: string = (
          timezone ? dayjs.tz(valueToUse, timezone) : dayjs(valueToUse)
        ).format(ChiroUpDayJsCommon.format.date);

      return ChiroUpDayJsCommon.cleanupDatetime(formatted);
    },
    timeFormat: (
      value?: string | number,
      format?: string,
      timezone?: string,
    ) => {
      if (!value) {
        return '';
      }
      let valueToUse = value;
      if (typeof valueToUse === 'string' && !isNaN(Number(valueToUse))) {
        valueToUse = Number(valueToUse);
      }
      // If it is a number and doesn't include milliseconds, add them.
      if (
        typeof valueToUse === 'number' &&
        valueToUse.toString().length === 10
      ) {
        valueToUse = convertToMS(valueToUse);
      }
      const dayjsValue = timezone
        ? dayjs.tz(valueToUse, timezone)
        : dayjs(valueToUse);
      const formatted = dayjsValue.format(format);
      return formatted;
    },
    time: (value?: string | number, timezone?: string) => {
      if (!value) {
        return '';
      }
      let valueToUse = value;
      if (typeof valueToUse === 'string' && !isNaN(Number(valueToUse))) {
        valueToUse = Number(valueToUse);
      }
      // If it is a number and doesn't include milliseconds, add them.
      if (
        typeof valueToUse === 'number' &&
        valueToUse.toString().length === 10
      ) {
        valueToUse = convertToMS(valueToUse);
      }
      const dayjsValue = timezone
        ? dayjs.tz(valueToUse, timezone)
        : dayjs(valueToUse);
      const formatted = dayjsValue.format(ChiroUpDayJsCommon.format.time);
      return ChiroUpDayJsCommon.cleanupDatetime(formatted);
    },
  },
};

/**
 * Created a separate function for this so it could be used in multiple
 * places. This implements the different value formats that it can process.
 *
 * @param value
 * @returns
 */
const getValueToUse = (value?: Date | string | number) => {
  let valueToUse = value;

  if (dayjs.isDayjs(value)) return value;

  /**
   * Try to coerce a Date or string into a number.
   */
  if (typeof value === 'object') {
    try {
      valueToUse = value.getTime();
    } catch (e: any) {
      return `-- ${typeof value} ${value} --`;
    }
  } else if (typeof value === 'string') {
    // Is it a string that represents a number? That is, a 10-digit or 13-digit unixish timestamp?
    const num = Number(value);
    if (!isNaN(num)) {
      if (value.length !== 10 && value.length !== 13) {
        return `-- ${typeof value} ${value} --`;
      } // Probably not what we're looking for in a number.
      valueToUse = num;
    } else {
      // Maybe a date string from MySQL that can be parsed by the Date constructor?
      valueToUse = new Date(value).getTime();
      if (isNaN(valueToUse)) {
        return `-- ${typeof value} ${value} --`; // Give up.
      }
    }
  }

  // If it is a number and doesn't include milliseconds, add them.
  if (typeof value === 'number' && value.toString().length === 10) {
    valueToUse = convertToMS(value);
  }
  return valueToUse;
};

export const ChiroUpBaseCommon = {
  notAuthorized: 'You are not authorized to access this page.',
  notFound: 'Page not found.',
  format: {
    asMoney: (value?: number | null, currency?: string) => {
      currency = currency || 'USD';
      if (currency !== 'USD') {
        return `value?.toFixed(2) ${currency} x`;
      }
      if (typeof value === 'string') {
        try {
          value = Number(value);
        } catch (e: any) {
          // Don't care.
        }
      }
      if (typeof value !== 'number' || isNaN(value)) {
        return `$$0.00 ${typeof value} y`;
      }
      try {
        const ret = `${value?.toFixed(2)}`,
          [before, after] = ret.split('.'),
          left = Number(before).toLocaleString();
        return value < 0
          ? `($${left.replace(/-/, '')}.${after})`
          : `$${left}.${after}`;
      } catch (e) {
        return '$0.00 z';
      }
    },
  },
};
