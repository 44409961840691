import { STRING_BOOLEAN_HASH } from '../constants';
import { Invoice } from './Invoice.type';
import { CCPaymentFields } from './PatientBillling.type';
import { ReceiptDetails } from './PatientTransaction.type';

export type PatientPayment = {
  id: number;
  number?: string;
  billingKey: string;
  clinicId: number;
  locationId?: number;
  patientId: string;
  paymentDate: string;
  amount: number;
  type: PaymentType | null;
  referenceNumber: number;
  subtype: PaymentType | null;
  description: string;
  createdAt: string;
  updatedAt: string;
  createdId: string;
  updatedId: string;
  transactionPayments?: TransactionPayment[];
  ePayRefNum?: string;
  ePayCustKey?: string;
  ePayData?: CCPaymentFields;
  credit?: boolean;
  deviceKey?: string;
  ePayBatchKey?: string;
  creditAmount?: number | null;
  isRefunded?: boolean;
  isVoided?: boolean;
  alertOverrode?: boolean;
  balance: number;
  invoices?: Partial<Invoice>[];
  receiptDetail?: ReceiptDetails;
  epayPublicKey?: string;
  processingFee?: number;
  selectedPaymentCard?: any;
  verified?: number | null;
  verifiedBy?: string | null;
  payorInvoiceId?: number;
  tz?: string;
};

export type TransactionPayment = {
  transactionId: number;
  paymentAmount: number;
  // totalDue: number;
  billingKey: string;
  void?: boolean;
  summary: {
    items: string[];
  };
};

export enum PaymentTypePatient {
  Cash = 'cash',
  CreditCard = 'creditCard',
  CreditCardExternal = 'creditCardExternal',
  CreditTransfer = 'creditTransfer',
  Check = 'check',
}

/**
 *                        !!! Important !!!
 *
 *   Make sure that PatientPaymentTypes and PayorPaymentTypes are updated
 *   when adding new payment types! Reporting and SQL queries will be
 *   fubar without these being accurate.
 */
export enum PaymentType {
  Cash = 'cash',
  CreditCard = 'creditCard',
  CreditCardExternal = 'creditCardExternal',
  CreditTransfer = 'creditTransfer',
  Check = 'check',
  WriteOff = 'writeOff',
  ACH = 'ACH',
  BOP = 'BOP',
  CHK = 'CHK',
  FWT = 'FWT',
  CCC = 'CCC',
}

export const PatientPaymentTypes = [
  PaymentType.Cash,
  PaymentType.CreditCard,
  PaymentType.CreditCardExternal,
  PaymentType.CreditTransfer,
  PaymentType.Check,
];

// A fast way to check if a payment type is for patients
export const PatientPaymentTypesHash = PatientPaymentTypes.reduce((a, c) => {
  a[c] = true;
  return a;
}, {} as STRING_BOOLEAN_HASH);

export const PayorPaymentTypes = [
  PaymentType.ACH,
  PaymentType.BOP,
  PaymentType.CHK,
  PaymentType.FWT,
  PaymentType.CCC,
];

// A fast way to check if a payment type is for payors
export const PayorPaymentTypesHash = PayorPaymentTypes.reduce((a, c) => {
  a[c] = true;
  return a;
}, {} as STRING_BOOLEAN_HASH);

/**
 *                           R E P O R T I N G
 *
 * These all have to do with the filtering of the payment detail report.
 * They are here as it seems easier to have them _with_ the rest of the
 * payment types and enums.
 */
export const PaymentEftTypes = [
  PaymentType.ACH,
  PaymentType.BOP,
  PaymentType.FWT,
];

export const PaymentCheckTypes = [PaymentType.Check, PaymentType.CHK];

export const PaymentCreditCardTypes = [
  PaymentType.CreditCard,
  PaymentType.CCC,
  PaymentType.CreditCardExternal,
];

// These are "groups" of payment types. The endpoint expands them.
export const PaymentReportOptions = [
  { value: 'cash', text: 'Cash' },
  { value: 'check', text: 'Check' },
  { value: 'creditCard', text: 'Credit Card' },
  { value: 'creditTransfer', text: 'Credit Transfer' },
  { value: 'EFT', text: 'EFT' },
];
/*
 * /End reporting.
 **/

export const PAYMENT_TYPE_DISPLAY = {
  cash: 'Cash',
  creditCard: 'Credit Card',
  creditCardExternal: 'Credit Card (External)',
  creditTransfer: 'Credit Transfer',
  check: 'Check',
  writeOff: 'Write off',
  ACH: 'Automated Clearinghouse (ACH)',
  BOP: 'Financial Institution Option',
  CHK: 'Check (Payor)',
  FWT: 'Federal Reserve Funds/Wire Transfer - Non-repetitive',
  CCC: 'Credit Card (Payor)',
};
