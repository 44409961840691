import {
  AddCodingDetails,
  Button,
  ButtonColors,
  Loading,
  TrivialTooltip,
} from '@chiroup/components';
import {
  AppointmentInsuranceType,
  ChiroUpJSON,
  ChiroUpTransactionCommon,
  ClinicCaseType,
  CodeSets,
  Insurance,
  PatientInsuranceTypes,
  PatientTransaction,
  PatientTransactionItemDiagnosisType,
  PatientTransactionItemType,
  PayorListType,
  ReferenceCodeDisplay,
  TransactionAppendItemsType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  USE_STATE_PAIR,
  VisitPlanType,
} from '@chiroup/core';
import React, { useCallback, useContext, useMemo } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import patientBillingService from '../../../../../../services/patientBilling.service';
import { databaseService } from '../../../../../settings/database/database.service';
import EncounterInsurance from '../insurances/EncounterInsurance';
import { InsuranceError } from './CodeEncounterDiagnosisServices';
import CodeEncounterServices from './CodeEncounterServices';
import { TransactionContext } from '../../../../../../contexts/transaction.context';
import SaltTransaction from './SaltTransaction';
import classNames from 'classnames';

const isaAppointment = (parentIsa?: string) => {
  return parentIsa === 'appointment';
};

const isaVisit = (parentIsa?: string) => {
  return (
    parentIsa === 'visit' ||
    parentIsa === 'encounter' ||
    parentIsa === 'transaction'
  );
};

const parentTitleCase = (parentIsa?: string, plural?: boolean) => {
  if (isaAppointment(parentIsa)) {
    return 'Appointment' + (plural ? 's' : '');
  } else if (isaVisit(parentIsa)) {
    return 'Visit' + (plural ? 's' : '');
  }
  return 'Ooops!';
};

const parentLowerCase = (parentIsa?: string, plural?: boolean) => {
  if (isaAppointment(parentIsa)) {
    return 'appointment' + (plural ? 's' : '');
  } else if (isaVisit(parentIsa)) {
    return 'visit' + (plural ? 's' : '');
  }
  return 'Ooops!';
};

type Props = {
  omitTitle: boolean;
  listofServices: PatientTransactionItemType[];
  isFetchingService: boolean;
  setListofServices: React.Dispatch<
    React.SetStateAction<PatientTransactionItemType[]>
  >;
  readonly?: boolean;
  isBillingStarted?: boolean;
  locked: boolean;
  parentIsa?: string;
  unlockButtonText: string;
  lockButtonText: string;
  saveButtonText: string;
  loadFromPlanAndAssessment: (e: any) => Promise<{
    services: PatientTransactionItemType[];
    insurances: Partial<AppointmentInsuranceType[]>;
  }>;
  lockButtonClicked: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  saveButtonClicked: ({
    e,
    disableToast,
    services,
    insurancesToUse,
  }: {
    e: React.MouseEvent<HTMLButtonElement>;
    disableToast?: boolean | undefined;
    services?: PatientTransactionItemType[] | null | undefined;
    insurancesToUse?: AppointmentInsuranceType[] | null | undefined;
  }) => Promise<void>;
  saveCallback?: (args: TransactionAppendItemsType) => void;
  setInsurances: (ins: Partial<AppointmentInsuranceType>[]) => void;
  setPayors: React.Dispatch<React.SetStateAction<any[]>>;
  patientId: string;
  insuranceErrors: InsuranceError[];
  services: PatientTransactionItemType[];
  plan?: VisitPlanType;
  isRestActive: {
    loadPlan: boolean;
    lock: boolean;
    save: boolean;
  };
  setIsFetchingService: React.Dispatch<React.SetStateAction<boolean>>;
  remoteControl?: boolean;
  disciplineId?: number;
  policies: any[];
  isFetchingPolicies: boolean;
  handlePolicyChange: (e: any) => void;
  patientTransactionState?: USE_STATE_PAIR<PatientTransaction>;
  lockButtonComponent?: React.ReactNode;
  allowBillingPriorityChange: boolean;
  courtesyBilling?: boolean;
  superBill?: boolean;
  handleCourtesyBillingChange: (e: boolean) => void;
  handleSuperBillChange?: (e: boolean) => void;
  providerId?: string;
  handleBillingProfileChange: (e: number) => void;
  billingProfileId?: number;
  caseType?: number | null;
  caseTypes?: Partial<ClinicCaseType>[];
  importButtonText?: string;
  importOnlyMode?: boolean;
  afterImport?: () => void;
};

const CodeEncounterDiagnosisServicesWithDiagnosis: React.FC<Props> = ({
  omitTitle,
  listofServices,
  isFetchingService,
  setListofServices,
  readonly = false,
  isBillingStarted = false,
  locked,
  parentIsa,
  unlockButtonText,
  lockButtonText,
  saveButtonText,
  loadFromPlanAndAssessment,
  lockButtonClicked,
  saveButtonClicked,
  saveCallback,
  setInsurances,
  patientId,
  insuranceErrors,
  services,
  plan,
  isRestActive,
  setIsFetchingService,
  remoteControl = false,
  disciplineId,
  policies,
  isFetchingPolicies,
  handlePolicyChange,
  setPayors,
  patientTransactionState,
  lockButtonComponent = null,
  allowBillingPriorityChange = false,
  courtesyBilling = false,
  superBill = false,
  handleCourtesyBillingChange,
  handleSuperBillChange,
  providerId,
  handleBillingProfileChange,
  billingProfileId,
  caseType,
  caseTypes,
  importButtonText = 'Import from wizard',
  importOnlyMode = false,
  afterImport,
}) => {
  const { me } = useContext(MeContext);
  const { insurances, isFetching: isFetchingInsurance } =
    useContext(TransactionContext);

  const updateTransactionWhenNeeded = useCallback(
    (services: PatientTransactionItemType[]) => {
      patientTransactionState?.set?.((prev) => {
        const clone = ChiroUpJSON.clone(prev) as PatientTransaction;
        const itemsWithoutServices =
          clone?.items?.filter(
            (item) =>
              item?.subtype !== TransactionItemSubtypeEnum.Service &&
              item?.subtype !== TransactionItemSubtypeEnum.PatientService,
          ) || [];
        clone.services = services;
        clone.items = [...itemsWithoutServices, ...services];
        return clone;
      });
    },
    [patientTransactionState],
  );

  const trivialTooltip = useMemo(() => {
    return {
      text: isBillingStarted ? ChiroUpTransactionCommon.billingStarted : '',
      id: 'main-tooltip',
    };
  }, [isBillingStarted]);

  const buttonColor = useMemo(
    () => (isBillingStarted ? ButtonColors.accent : ButtonColors.primary),
    [isBillingStarted],
  );

  const onChangeServices = (
    index: number,
    val: PatientTransactionItemType | null,
  ) => {
    const newServices = [...(listofServices || [])];
    if (val) {
      newServices[index] = val;
    } else {
      newServices.splice(index, 1);
    }
    setListofServices(newServices);
    updateTransactionWhenNeeded(newServices);
  };

  const onPromote = useCallback(
    async (e: ReferenceCodeDisplay, getRidOfService?: string) => {
      if (isFetchingService) return;

      setIsFetchingService(true);

      /**
       * Requirements from story:
       *    Prioritize the population of Modifiers in the following
       *    order: Clinic default, Primary policy Payor, Secondary
       *    policy Payor, Tertiary policy Payor, and so on.
       *
       * Implementation basics:
       *    Low numbers are a higher priority. If a modifier is used
       *    multiple times if there are multiple payors, the score is
       *    lowered by one each time it is used assuming it is a very
       *    important one.
       *
       * Fire of the REST calls in parallel.
       */
      const proms: Promise<any>[] = [];
      let code: PayorListType = {};
      let ins: Insurance[] = [];

      try {
        proms.push(
          databaseService
            .getCode({
              clinicId: me?.selectedClinic?.ID,
              code: e.code,
              payors: true,
            })
            .then((r: any) => {
              code = r.data as PayorListType;
              setPayors((prevPayors) => {
                const newPayors =
                  code.payors?.map((payor: any) => ({
                    payorID: payor.payorIntPk,
                    allowedAmount: +payor.allowedAmount,
                    code: e.code,
                    replacementCode: payor.replacementCode,
                  })) || [];

                return [...(prevPayors || []), ...(newPayors || [])];
              });
              const objs = (insurances || []).map(
                (insurance: Partial<AppointmentInsuranceType>) => {
                  const matchedPayor = code.payors?.find(
                    (payor: any) => payor.payorIntPk === insurance.payorID,
                  );
                  // console.log({ matchedPayor });
                  const allowedAmount = Number(
                    matchedPayor?.allowedAmount || code?.billedAmount || 0,
                  );
                  return {
                    ...insurance,
                    serviceAllowedAmounts: {
                      ...insurance.serviceAllowedAmounts,
                      [e.code]: {
                        allowedAmount,
                        payorID: insurance.payorID as number,
                      },
                    },
                  };
                },
              );
              setInsurances(objs);
              return r;
            }),
        );
        proms.push(
          patientBillingService
            .listPatientInsurance(me?.selectedClinic?.ID, patientId)
            .then((r) => {
              ins = r.data;
              return r;
            }),
        );
      } catch (e) {
        console.error({ e });
      }
      await Promise.all(proms);

      const hasInsuranceHash = ins.reduce((a: any, c: Insurance, i: number) => {
        if (c && c.type && c.payorID) {
          a[c.payorID] =
            c.type === PatientInsuranceTypes.Primary
              ? 10 + i
              : PatientInsuranceTypes.Secondary
                ? 11 + i
                : 12 + i;
        }
        return a;
      }, {});

      /**
       * Confusing, but we go through the payors for this service and
       * find out if the patient HAS that insurance. If they do, we try
       * to calculate a count as to how many times it is used so we can
       * sort this bad boys...eventually.
       */
      const modifierHash = (code?.payors || []).reduce((a: any, p: any) => {
        const payorId = p?.payorId || '-bogus-';
        if (hasInsuranceHash[payorId]) {
          ['modifier1', 'modifier2', 'modifier3', 'modifier4'].forEach(
            (s: string, i: number) => {
              if (p[s]) {
                if (a[p[s]]) {
                  a[p[s]] = a[p[s]] - 1 + i; // lower is better. Used more than once.
                } else {
                  a[p[s]] = hasInsuranceHash[payorId] + i;
                }
              }
            },
          );
        }
        return a;
      }, {});

      /**
       * _NOW_ add the default modifiers for the service. The payor modifiers
       * are already there. I tried to do this in a loop, but TSLINT and the
       * compiler would have no part of it. Brute force was expedient... Feel
       * free to refactor!!!
       */
      if (code?.modifier1) {
        if (modifierHash[code.modifier1]) {
          modifierHash[code.modifier1] = modifierHash[code.modifier1] - 1;
        } else {
          modifierHash[code.modifier1] = 1;
        }
      }
      if (code?.modifier2) {
        if (modifierHash[code.modifier2]) {
          modifierHash[code.modifier2] = modifierHash[code.modifier2] - 1;
        } else {
          modifierHash[code.modifier2] = 2;
        }
      }
      if (code?.modifier3) {
        if (modifierHash[code.modifier3]) {
          modifierHash[code.modifier3] = modifierHash[code.modifier3] - 1;
        } else {
          modifierHash[code.modifier3] = 3;
        }
      }
      if (code?.modifier4) {
        if (modifierHash[code.modifier4]) {
          modifierHash[code.modifier4] = modifierHash[code.modifier4] - 1;
        } else {
          modifierHash[code.modifier4] = 4;
        }
      }

      const modifiers = Object.keys(modifierHash).sort(function (a, b) {
        return modifierHash[a] - modifierHash[b];
      });

      if (code) {
        const nobj = JSON.parse(JSON.stringify(code));
        nobj.diagnoses = [] as PatientTransactionItemDiagnosisType[];
        nobj.units = 1;
        nobj.type = TransactionItemTypeEnum.Debit;
        nobj.subtype = TransactionItemSubtypeEnum.Service;
        nobj.amount = code.billedAmount ? +code.billedAmount : 0;
        nobj.modifier1 = modifiers[0] || null;
        nobj.modifier2 = modifiers[1] || null;
        nobj.modifier3 = modifiers[2] || null;
        nobj.modifier4 = modifiers[3] || null;
        nobj.insuranceBillable = true;

        const servicesToUse = JSON.parse(JSON.stringify(listofServices));
        setListofServices((prev: PatientTransactionItemType[]) => {
          const newVal = [...(prev || [])];
          if (getRidOfService) {
            const serviceIndex = newVal.findIndex(
              (service) => service.code === getRidOfService,
            );
            if (serviceIndex > -1) {
              newVal[serviceIndex] = nobj;
            }
          } else {
            newVal.push(nobj);
          }
          return newVal as PatientTransactionItemType[];
        });

        const valToSend = servicesToUse;
        if (getRidOfService) {
          const serviceIndex = valToSend.findIndex(
            (service: any) => service.code === getRidOfService,
          );
          if (serviceIndex > -1) {
            valToSend[serviceIndex] = nobj;
          }
        } else {
          valToSend.push(nobj);
        }
        updateTransactionWhenNeeded(valToSend);
      }
      setIsFetchingService(false);
    },
    [
      insurances,
      isFetchingService,
      me?.selectedClinic?.ID,
      patientId,
      setInsurances,
      setIsFetchingService,
      listofServices,
      setListofServices,
      setPayors,
      updateTransactionWhenNeeded,
    ],
  );

  const onRemoveInsurance = useCallback(
    (policy: Partial<AppointmentInsuranceType>) => {
      const next = ChiroUpJSON.clone(insurances).filter(
        (p: Partial<AppointmentInsuranceType>) => {
          return p.insuranceID !== policy.insuranceID;
        },
      );
      setInsurances(next);
    },
    [insurances, setInsurances],
  );

  const activePolicies = useMemo(() => {
    return (policies || [])
      ?.filter((policy: Insurance) => policy.active)
      ?.filter(
        (ap: Insurance) =>
          !(insurances || []).some((policy) => ap.id === policy.insuranceID),
      )
      ?.map((policy: Insurance) => ({
        text: policy?.name || '',
        value: policy,
      }));
  }, [policies, insurances]);

  const doThis = useCallback(
    async (e: any) => {
      const { services, insurances } = await loadFromPlanAndAssessment(e);
      await saveButtonClicked({
        e: e as React.MouseEvent<HTMLButtonElement>,
        services,
        insurancesToUse: insurances as AppointmentInsuranceType[],
      });
      afterImport?.();
    },
    [loadFromPlanAndAssessment, saveButtonClicked, afterImport],
  );

  if (importOnlyMode) {
    return (
      <div>
        {plan && Object.keys(plan).length > 0 && (
          <div className="flex flex-row space-x-4">
            <Button
              text={importButtonText}
              loading={isRestActive.loadPlan || isRestActive.save}
              className="print:hidden"
              disabled={
                locked ||
                isFetchingInsurance ||
                isFetchingService ||
                isRestActive.loadPlan
              }
              onClick={doThis}
              color={buttonColor}
              trivialTooltip={trivialTooltip}
            />
            {/* <pre>{ChiroUpJSON.pretty({ insurances })}</pre> */}
            {/* <Button
              text={`${saveButtonText}`}
              loading={isRestActive.save}
              className="print:hidden"
              disabled={!!insuranceErrors.length}
              onClick={(e: unknown) => {
                saveButtonClicked({
                  e: e as React.MouseEvent<HTMLButtonElement>,
                });
              }}
            />{' '} */}
          </div>
        )}
        {/* <pre>{ChiroUpJSON.pretty(plan)}</pre> */}
      </div>
    );
  }

  return (
    <div className="w-full">
      {isFetchingService && (!listofServices || listofServices.length === 0) ? (
        <div className="flex justify-center m-4">
          <Loading color="text-gray-400" />
        </div>
      ) : null}
      {!readonly || (readonly && listofServices.length > 0) ? (
        <div
          className={classNames(
            omitTitle ? '' : 'mt-4 ',
            'relative block text-lg font-medium leading-5',
            isBillingStarted ? 'text-accent-600' : 'text-primary-600',
          )}
        >
          {!omitTitle ? `Services for this ${parentTitleCase(parentIsa)}` : ''}
          {!omitTitle ? (
            <TrivialTooltip
              text={`${parentTitleCase(
                parentIsa,
                true,
              )} may have any number of services. 
  A single service must have at least one diagnosis and may have up to four. 
  Add diagnoses in order: primary, secondary, tertiary, and quaternary.
  A maximum of 12 diagnoses may be associated with one appointment.
  The number of units and a billed amount are required.`}
              tipClassName="w-96 text-sm"
            />
          ) : null}
        </div>
      ) : null}
      <CodeEncounterServices
        services={listofServices}
        readonly={readonly || locked}
        isBillingStarted={isBillingStarted}
        onChangeValue={onChangeServices}
        onPromote={onPromote}
      />
      <div className="w-full p-0">
        {!readonly && !locked && (
          <div className="flex flex-col space-y-4">
            <SaltTransaction
              target={
                {
                  ...patientTransactionState?.value,
                  insurances,
                } as PatientTransaction
              }
              parentIsa={parentIsa}
              callback={(txn: PatientTransaction) => {
                setListofServices(
                  (txn.items || []).filter(
                    (i) => i.subtype === TransactionItemSubtypeEnum.Service,
                  ),
                );
                setInsurances(txn.insurances || []);
                setPayors(txn.payors || []);
                if (typeof saveCallback === 'function') {
                  saveCallback({ newTransaction: txn });
                }
              }}
            />

            <AddCodingDetails
              label="Services"
              value={listofServices}
              codeSet={[CodeSets.CPT, CodeSets.SERVICE]}
              noCodesMessage={`No services are associated with this ${parentLowerCase(
                parentIsa,
              )}.`}
              noneText="Type to search and add service codes..."
              onPromote={onPromote}
              isPromoting={isFetchingService}
              promoteTitle={`Add this service to the ${parentLowerCase(
                parentIsa,
              )}.`}
              repeatTitle={`This service is already associated with the ${parentLowerCase(
                parentIsa,
              )}.`}
              autoCompleteContainerClassName="mr-0.5 flex flex-row gap-4"
              clinicId={me.selectedClinic?.ID as number}
              isBillingStarted={isBillingStarted}
            />
          </div>
        )}
      </div>
      {caseTypes?.find((c) => c.id === caseType)?.name !== 'Cash' && (
        <>
          <EncounterInsurance
            activePolicies={activePolicies}
            allowBillingPriorityChange={allowBillingPriorityChange}
            billingProfileId={billingProfileId}
            courtesyBilling={courtesyBilling}
            disciplineId={disciplineId}
            handleBillingProfileChange={handleBillingProfileChange}
            handleCourtesyBillingChange={handleCourtesyBillingChange}
            handlePolicyChange={handlePolicyChange}
            handleSuperBillChange={handleSuperBillChange}
            insuranceErrors={insuranceErrors}
            insurances={insurances}
            isBillingStarted={isBillingStarted}
            isFetchingPolicies={isFetchingPolicies}
            onRemoveInsurance={onRemoveInsurance}
            parentIsa={parentIsa}
            patientId={patientId}
            policies={policies}
            providerId={providerId}
            readOnly={readonly}
            services={(services || []).filter(
              (item) => item.subtype === TransactionItemSubtypeEnum.Service,
            )}
            setInsurances={setInsurances}
            superBill={superBill}
            billingKey={patientTransactionState?.value?.billingKey as string}
          />
          <InsuranceErrors errors={insuranceErrors} />{' '}
        </>
      )}
      <div className="flex flex-row justify-end space-x-4">
        {patientTransactionState?.value?.services?.length && !readonly ? (
          lockButtonComponent ? (
            lockButtonComponent
          ) : (
            <Button
              text={locked ? unlockButtonText : lockButtonText}
              loading={isRestActive.save}
              className="mt-5 print:hidden"
              onClick={lockButtonClicked}
              color={buttonColor}
              trivialTooltip={trivialTooltip}
            />
          )
        ) : null}
        {!readonly && !remoteControl && (
          <>
            {plan && Object.keys(plan).length > 0 && (
              <Button
                text={importButtonText}
                loading={isRestActive.loadPlan}
                className="mt-5 print:hidden"
                disabled={locked || isFetchingService || isRestActive.loadPlan}
                onClick={async (e: any) => {
                  await loadFromPlanAndAssessment(e);
                }}
                color={buttonColor}
                trivialTooltip={trivialTooltip}
              />
            )}
            <Button
              text={`${saveButtonText}`}
              loading={isRestActive.save}
              className="mt-5 print:hidden"
              disabled={!!insuranceErrors.length}
              onClick={(e: unknown) => {
                saveButtonClicked({
                  e: e as React.MouseEvent<HTMLButtonElement>,
                });
              }}
              color={buttonColor}
              trivialTooltip={trivialTooltip}
            />
          </>
        )}
      </div>
    </div>
  );
};

type InsuranceErrorsProps = {
  errors?: InsuranceError[];
  className?: string;
};

export const InsuranceErrors: React.FC<InsuranceErrorsProps> = ({
  errors,
  className = '',
}) => {
  if (!errors || errors.length === 0) return null;

  return (
    <div className="border border-red-600 px-4 py-2 rounded-md">
      {errors.map((error, index) => (
        <div
          key={`cedswd-errors-${index}`}
          className={['my-2 text-sm text-red-600', className].join(' ')}
        >
          {error.message}
        </div>
      ))}
    </div>
  );
};

export default CodeEncounterDiagnosisServicesWithDiagnosis;
