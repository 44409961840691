import { Dayjs } from 'dayjs';
import { PatientSurvey } from './PatientSurvey.type';
import { PatientTransactionItemType } from './PatientTransaction.type';
import { ReferenceCodeDisplay } from './ReferenceCode.type';
import { BodyParts, SourceOfPain, SurveyQuestion } from './Survey.type';
import { TestOrder } from './Test.type';
import { Vital } from './Vital.type';
import { SELECT_OPTION_TYPE, STRING_STRING_HASH } from '../constants';
import { CaseSubType } from '../enums';
import { Modalities } from './Modalities.type';
import Decimal from 'decimal.js';

export type VisitTimes = {
  id?: number;
  times: number;
  timesUnit: 'day' | 'week' | 'month';
  duration: number;
  durationUnit: 'day' | 'week' | 'month';
  createdAt?: number;
  carePlanId?: number;
};

export type EncounterInfo = {
  id?: number;
  carePlanId?: number;
  visitNumber?: number;
  nextReEval?: number;
  createdBy?: string;
  createdAt?: number;
  updatedBy?: string;
  updatedAt?: number;
  visits?: VisitTimes[];
  totalVisits?: number;
  complaints?: CarePlanComplaint[];
  communications?: string[];
  visitComplaintSurveyId?: string;
  plan?: string;
  goal?: number;
  tests?: TestOrder[];
  otherSurveys?: PatientSurvey[];
  patientId?: string;
  clinicId?: number;
  active?: boolean;
  nextVisitType?: string;
  encounters?: {
    id: string;
    visitStatus: string;
    visitDate: string;
  }[];
  claimInfo?: ClaimInfo;
  visitTimes?: VisitTimes[];
  notes?: string;
  resetCarePlan?: boolean;
  nextRequestedEncounterDate?: string | number | null;
};

export type Complaint = {
  id: string;
  patientId: string;
  clinicId: number;
  status: ComplaintStatuses;
  survey: PatientSurvey;
  origin?: ComplaintOrigins;
  inactiveReason?: string;
  surveyId?: string;
  complaint?: string;
  region?: string;
  bodypicker?: {
    parts: BodyParts;
    sourceOfPain: SourceOfPain;
  };
  surveyQuestions?: { survey: { questions: SurveyQuestion[] } };
  problemArea?: number;
  questions?: {
    questions: SurveyQuestion[];
    english: { questions: SurveyQuestion[] };
  };
  createdAt: number;
  updatedAt: number;
  added?: number;
  prn: boolean;
};

export type ConditionReferenceCodeDisplay = ReferenceCodeDisplay & {
  conditionId: number;
  // primary: boolean;
  order?: number; // Can sort by this at some point, maybe?
  referenceCodes?: ReferenceCodeDisplay[];
  essentialCondition: boolean;
};

export type CarePlanComplaint = {
  id?: string;
  origin?: ComplaintOrigins;
  surveyId?: string;
  complaint?: string;
  skipped?: boolean;
  region?: string;
  subjective?: string;
  objective?: string;
  assessment?: {
    nonConditions?: { referenceCodes: ReferenceCodeDisplay[] };
    conditions?: ConditionReferenceCodeDisplay[];
    goal?: number;
    text?: string;
    value?: ImprovementOptions | null;
  };
  bodypicker?: {
    parts: BodyParts;
    sourceOfPain: SourceOfPain;
  };
  surveyQuestions?: { survey: { questions: SurveyQuestion[] } };
  problemArea?: number;
  questions?: {
    questions: SurveyQuestion[];
    english: { questions: SurveyQuestion[] };
  };
  added: number;
  createdAt?: number;
  status: ComplaintStatuses;
  survey?: PatientSurvey;
  inactiveReason?: string;
  deactivatedReferredProviderId?: number;
  prn: boolean;
};

export type VisitModality = {
  visitId: string;
  modalityId: number;
};

export type ModalityHistory = {
  ID: number;
  completedAt?: Date | string;
  completedBy?: string;
  completedByName?: string;
  heat: boolean;
  ice: boolean;
  intensity: number;
  name?: string;
  region: string[];
  time: number;
  visitId: string;
};

export enum ComplaintStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ComplaintInactiveReasons {
  RESOLVED = 'resolved',
  REFERRED = 'referred',
  OTHER = 'other',
}

export const enum ComplaintOrigins {
  CCS = 'ccs',
  Reeval = 'reeval',
}

export const enum SOAPTemplates {
  Blank = 'Blank SOAP',
  Wizard = 'SOAP Wizard',
}

export type OfficeExerciseHistory = {
  id: number;
  patientId: string;
  carePlanId: number | null;
  visitId: string;
  completedBy: string;
  completedAt: string;
  clinicianName?: string;
  exerciseNames?: string;
};
export type DetailObject = {
  notes?: string;
  type?: string;
  region?: string;
};

export type VisitPlanKeyType = {
  [key: number | string]: {
    units?: number;
    services?: string[];
    unitServices?: any;
  };
};

export type VisitPlanType = {
  [key: string]: any; // Get out of jail free card.
  evaluationAndManagement?: VisitPlanKeyType;
  modalities?: VisitPlanKeyType;
  supplementsAndSupplies?: VisitPlanKeyType;
  imagingAndTests?: VisitPlanKeyType;
  treatments?: TreatmentObject;
  rehab?: VisitPlanKeyType;
  courtesyBilling?: boolean;
};

export type VisitDetail = {
  tests?: {
    id: number;
    notes: string;
    sides: string;
    views: number;
    region: string;
    facility: number;
    testName: string;
    facilityName: string;
  }[];
  vitals: Vital;
  exercises: {
    currentExercises: {
      ID: number;
      name: string;
      order: number;
      phase: number;
    }[];
    inOfficeExercises: {
      ID: number;
      name: string;
      order: number;
      phase: number;
    }[];
  };
  modalities: Partial<Modalities>[];
  communications: {
    exerciseReports: any[];
    conditionReports: any[];
  };
};

export type Visit = {
  id?: string;
  createdAt?: Dayjs | number;
  updatedAt?: Dayjs | number;
  clinician?: {
    id?: string;
    name?: string;
    degree?: string;
  };
  exercisesComplete?: boolean;
  encounterInfo?: EncounterInfo;
  note?: { [key: string]: SOANote } | null;
  ccsSurveyId?: string;
  visitDate?: number | string | null | Dayjs;
  patientId?: string;
  clinicId?: number;
  locationId?: number;
  carePlanId?: number;
  visitNumber?: number;
  carePlanTotalVisits?: number;
  interimSurveyId?: string;
  interimSurvey?: PatientSurvey;
  reevaluationSurvey?: PatientSurvey;
  reevaluationSurveyId?: string;
  reevaluationSurveyIds?: string[];
  additionalComplaintSurveyIds?: string[];
  soapTemplate?: SOAPTemplates | null;
  notes?: blankSOAPNOTE | null;
  signed?: string | null;
  signedAt?: number | null;
  fullSignatureLink?: string;
  visitStatus?: VisitStatus;
  testsComplete?: boolean;
  modalitiesComplete?: boolean;
  modalitiesCompletedAt?: Dayjs | number | null;
  modalitiesLastUpdated?: Dayjs | number;
  vitals?: Partial<Vital>;
  vitalsComplete?: boolean;
  prId?: string;
  lastVisitObjectiveData?: { [key: string]: ObjectiveNote };
  plan?: VisitPlanType | null;
  surveys?: any;
  allSurveys?: any;
  services?: PatientTransactionItemType[];
  importDiagnosesAlways?: boolean;
  mostRecentEncounterNumber?: number;
  tz?: string;
  externalPrintReasons?: string | null;
  hasAppointment?: boolean;
  insuranceSubmitted?: string;
  lifetimeVisits?: number;
  caseSubtype?: CaseSubType;
  soapCustomEditWarning?: {
    subjective?: boolean;
    objective?: boolean;
    assessment?: boolean;
    plan?: boolean;
  };
  detail?: VisitDetail;
};

export type TreatmentObject = {
  [key: string]: {
    [key: string]: {
      units?: number;
      detail?: { [key: string]: any };
      detailNotes?: string;
    };
  };
};

export enum VisitStatus {
  Completed = 'completed',
  InProgress = 'inProgress',
  NotStarted = 'notStarted',
}

export type Note = {
  id: string;
  complaints?: CarePlanComplaint[];
  createdAt: number;
  updatedAt: number;
  plan: string;
  carePlanId?: number;
  visitNumber?: number;
};

export enum ImprovementOptions {
  SignificantlyWorse = 'SignificantlyWorse',
  Worse = 'Worse',
  Unchanged = 'Unchanged',
  Improved = 'Improved',
  SignificantlyImproved = 'SignificantlyImproved',
}

export enum ImprovementOptionsText {
  SignificantlyWorse = 'Significantly Worse',
  Worse = 'Worse',
  Unchanged = 'Unchanged',
  Improved = 'Improved',
  SignificantlyImproved = 'Significantly Improved',
}

export enum ObjectiveParts {
  RangeOfMotion = 'RangeOfMotion',
  Joints = 'Joints',
  Myofascial = 'Myofascial',
  Orthopedic = 'Orthopedic',
  Neurological = 'Neurological',
}

type ObjectiveNoteDetails = {
  [key in ObjectiveParts]?: {
    value?: ImprovementOptions;
    detail?: any;
    side?: string;
    touched?: boolean;
    showGlobal?: boolean;
  };
};
export type ObjectiveNote = ObjectiveNoteDetails & {
  extraNotes?: string;
};

export type SOANote = {
  subjective?: { type: 'doc'; content: any[] } | string;
  objective?: ObjectiveNote;
  assessment?: string;
  conditions?: number[];
  complaintId?: number | string;
};

export type blankSOAPNOTE = {
  subjective?: string | { type: 'doc'; content: any[] };
  objective?: string;
  assessment?: string;
  plan?: string;
  signatureKey?: string;
  additionalSections?: { [key: string]: string }[];
};

export type ComplaintCondition = {
  complaintId: number;
  conditionId: number;
};

export type ComplaintConditions = {
  complaintId: number;
  conditions: number[];
};

export type ClaimInfo = {
  nature?: ClaimNature;
  onsetDate?: string | number;
  // relatedEvent?: ClaimRelatedEvent;
  // relatedEventDate?: string | number;
  disabilityStartDate?: string | number;
  disabilityEndDate?: string | number;
  unableWorkStartDate?: string | number;
  unableWorkEndDate?: string | number;
  hospitalStartDate?: string | number;
  hospitalEndDate?: string | number;
  providerType?: ProviderType;
  providerId?: string | number;
  providerName?: string;
  providerNpi?: string;
  idNumberType?: IdNumberType;
  idNumber?: string;
  employment?: boolean;
  auto?: boolean;
  autoState?: string;
  otherAccident?: boolean;
  accidentDate?: string | number;
  acuteManifestationChronicConditionDate?: string | number;
  firstVisitDate?: string | number;
  initialTreatmentDate?: string | number;
  lastXRayDate?: string | number;
  latestVisitDate?: string | number;
  prescriptionDate?: string | number;
  reportStartDate?: string | number;
  reportEndDate?: string | number;
  EPSDTRefferal?: EPSDTRefferalType;
};

export type TransactionClaimsWriteOffInfo = {
  billingKey: string;
  clinicId: number;
  canWriteOff: boolean;
  canAllocate: boolean;
  totalBilledAmount: number;
  adjudicatedContractualAdjustmentAmount: number;
  fullPayorPaidAmount: number;
  adjudicatedPatientResponsibilityAmount: number;
  patientServiceAmount: number;
  unallocatedAmount: number;
  patientPaymentAmount: number;
  claimWriteOffTotal: number;
  amountAllocatedToPatient: number;
};

export type TransactionClaimsAllocationInfo = {
  billingKey: string;
  clinicId: number;
  canAllocate: boolean;
  allocationAmount: number;
  patientResponsibilityAmount: number;
};

export enum EPSDTRefferalType {
  Yes = 'yes',
  No = 'no',
  AvailableNotUsed = 'available',
  UnderTreatment = 'underTreatment',
  NewServiceRequested = 'newService',
  NotUsed = 'notUsed',
  NotApplicable = 'notApplicable',
}

export const EdiIncludeEPSDTRefferal = {
  [EPSDTRefferalType.Yes]: false,
  [EPSDTRefferalType.No]: true,
  [EPSDTRefferalType.AvailableNotUsed]: true,
  [EPSDTRefferalType.UnderTreatment]: true,
  [EPSDTRefferalType.NewServiceRequested]: true,
  [EPSDTRefferalType.NotUsed]: true,
  [EPSDTRefferalType.NotApplicable]: false,
};

export enum ClaimNature {
  AcuteCondition = 'acute',
  ChronicCondition = 'chronic',
  NonAcute = 'nonAcute',
  NonLifeThreatening = 'nonLifeThreatening',
  Routine = 'routine',
  Symptomatic = 'symptomatic',
  AcuteManifestation = 'acuteManifestation',
}

export const ClaimNatureEdiXref = {
  [ClaimNature.AcuteCondition]: 'A',
  [ClaimNature.ChronicCondition]: 'C',
  [ClaimNature.NonAcute]: 'D',
  [ClaimNature.NonLifeThreatening]: 'E',
  [ClaimNature.Routine]: 'F',
  [ClaimNature.Symptomatic]: 'G',
  [ClaimNature.AcuteManifestation]: 'M',
};

export enum ClaimRelatedEvent {
  InitialTreatment = '454',
  Latest = '304',
  Acute = '453',
  Accident = '439',
  XRay = '455',
  Prescription = '471',
  ReportStart = '090',
  ReportEnd = '091',
  FirstVisit = '444',
}

export const ClaimRelatedEventReverseXref: STRING_STRING_HASH = {
  '454': 'Initial Treatment',
  '304': 'Latest Visit or Consultation',
  '453': 'Acute Manifestation of a Chronic Condition',
  '439': 'Accident',
  '455': 'Last X-ray',
  '471': 'Prescription',
  '090': 'Report Start (Assumed Care Date)',
  '091': 'Report End (Relinquished Care Date)',
  '444': 'First Visit or Consultation',
};

// Options are alphabetized by their description. Easy to change if needed.
// They were just in a random order it seemed.
export const ClaimRelatedEventSelectOptions = Object.values(ClaimRelatedEvent)
  .reduce((a, v) => {
    a.push({ text: `${v} - ${ClaimRelatedEventReverseXref[v]}`, value: v });
    return a;
  }, [] as SELECT_OPTION_TYPE[])
  .sort((a, b) => {
    const aText = ClaimRelatedEventReverseXref[a.value],
      bText = ClaimRelatedEventReverseXref[b.value];
    return aText > bText ? 1 : bText > aText ? -1 : 0;
  });

export const ClaimNatureSelectOptions: {
  text: string;
  value: ClaimNature;
}[] = [
  {
    text: 'Acute Condition',
    value: ClaimNature.AcuteCondition,
  },
  {
    text: 'Chronic Condition',
    value: ClaimNature.ChronicCondition,
  },
  { text: 'Non-acute', value: ClaimNature.NonAcute },
  {
    text: 'Non-life threatening',
    value: ClaimNature.NonLifeThreatening,
  },
  { text: 'Routine', value: ClaimNature.Routine },
  { text: 'Symptomatic', value: ClaimNature.Symptomatic },
  {
    text: 'Acute Manifestation of a Chronic Condition',
    value: ClaimNature.AcuteManifestation,
  },
];

/**
 * TODO: Yeah, this is weird. No "billing" provider. No way
 * to add more than one on a claim.
 */
export enum ProviderType {
  ReferringProvider = 'referingProvider',
  OrderingProvider = 'orderingProvider',
  SupervisingProvider = 'supervisingProvider',
}

export enum IdNumberType {
  StateLicenseNumber = 'state',
  UPINNumber = 'upin',
  ProviderCommercialNumber = 'commercial',
}
