import React, { useEffect, useState } from 'react';
import CodeEncounterService from './CodeEncounterService';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  PatientTransactionItemType,
  ReferenceCodeDisplay,
} from '@chiroup/core';

type Props = {
  services: PatientTransactionItemType[];
  onChangeValue: (
    index: number,
    val: PatientTransactionItemType | null,
  ) => void;
  readonly: boolean;
  isBillingStarted?: boolean;
  onPromote: (
    e: ReferenceCodeDisplay,
    getRidOfService?: string,
  ) => Promise<void>;
};

const CodeEncounterServices: React.FC<Props> = ({
  services,
  onChangeValue,
  readonly,
  isBillingStarted = false,
  onPromote,
}) => {
  const [servicesWithId, setServicesWithId] = useState<any>([]);

  useEffect(() => {
    if (!services) return setServicesWithId([]);

    setServicesWithId(
      services
        .map((service) => {
          if (!service) return null;
          return {
            ...service,
            id: service.code as string,
          };
        })
        .filter((service) => !!service),
    );
  }, [services]);

  if (!services?.length) return null;
  const usableServices = services.filter((service) => !!service);
  if (!usableServices?.length || usableServices.length === 0) return null;

  return (
    <SortableContext
      id="services"
      items={servicesWithId}
      strategy={verticalListSortingStrategy}
    >
      {services.map((service: PatientTransactionItemType, i: number) => (
        <CodeEncounterService
          key={`${service.code}-${i}`}
          index={i}
          service={service}
          onChangeValue={onChangeValue}
          readonly={readonly}
          isBillingStarted={isBillingStarted}
          onPromote={onPromote}
        />
      ))}
    </SortableContext>
  );
};

export default CodeEncounterServices;
