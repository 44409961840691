import { FlagGroups } from '../constants';
import { ClinicLocation } from './Clinic.type';
import { UserRoles } from './User.type';

export type Prices = {
  basic: number;
  plus: number;
  premium: number;
  additionalLocations: number;
  coupon?: {
    amount_off?: number;
    percent_off?: number;
    duration?: string;
    duration_in_months?: number;
    code?: string;
    appliesToEverything?: boolean;
    freeMonth?: boolean;
  };
};

export type Clinician = {
  ID: string;
  name: string;
  degree: string;
  fname: string;
  lname: string;
  profileImage: string | null;
};

export type Subscription = {
  amount?: number;
  name?: string;
  plan?: string;
  pastDueDays?: number;
  subscriptionPrices?: Prices;
  profile?: {
    customerProfileId?: string;
    description?: string;
    merchantCustomerId?: string;
    paymentProfile?: {
      billTo?: {
        firstName: string;
        lastName: string;
      };
      customerPaymentProfileId?: string;
      customerType?: string;
      payment: {
        creditCard?: { cardNumber: string; expirationDate: string };
        bankAccount?: {
          accountNumber: string;
          routingNumber: string;
        };
      };
    };
  };
  status?:
    | 'active'
    | 'expired'
    | 'suspended'
    | 'canceled'
    | 'terminated'
    | 'past_due'
    | 'requires_action'
    | 'incomplete'
    | 'trialing';
  trialAmount?: number;
  locations?: number;
  created?: string;
  callForHelp?: boolean;
};

export type PriceTiers = {
  basic: number;
  plus: number;
  premium: number;
  trial?: boolean;
  education?: boolean;
};

export type Plan = keyof PriceTiers;

export type MeClinicSettingType = {
  ID: number;
  section: string;
  setting: string;
  jsonValue: any;
};

export type MeClinic = {
  ID: number;
  name: string;
  location: string;
  logo: string;
  language: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  fax: string;
  email: string;
  ehr?: boolean;
  site: string;
  facebook: string;
  twitter: string;
  youtube: string;
  surveyOptOut: boolean;
  theme: string;
  plan: Plan;
  clinicians: Clinician[];
  role: UserRoles[];
  subscription: Subscription;
  bypassPaymentGateway: boolean;
  freeAccount: boolean;
  subscriptionAmount: number;
  subscriptionID: number;
  trialCount: number;
  trialEndDate?: string;
  googleReviewPlaceID?: string;
  prices?: Prices;
  settings?: MeClinicSettingType[];
  billDate: string;
  termsOfUse: string;
  expired?: boolean;
  kioskID: string;
  primaryColor?: string;
  accentColor?: string;
  noPatientCommunication?: number;
  updatingIcons: number;
  stripePaymentMethod?: any;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
  stripeFailureMessage?: string;
  referralCode?: string;
  gradCouponName?: string;
  flags?: {
    [key: string]: boolean;
  };
  flagGroup?: FlagGroups;
  locations: ClinicLocation[];
  postingSchedule?: 'A' | 'B' | null;
  plusUntil?: Date | null;
  premiumUntil?: Date | null;
  emrWalkthroughComplete?: boolean;
  ehrMigrationAllowed?: boolean;
  patientMergeAllowed?: boolean;
  signedNoteTimestamp?: boolean;
};

export type StudentRecord = {
  status: string;
  graduationDate: string;
  studentId?: string;
};

export type Me = {
  ID: string;
  clinics: MeClinic[];
  createDate?: string;
  dashboardData?: {
    knowledgebase: any[];
    videos: any[];
    walkthroughs: any[];
  };
  degree: string;
  email: string;
  fname: string;
  middleInitial?: string;
  suffix?: string;
  isProvider?: boolean;
  lname: string;
  mfa: boolean;
  name: string;
  phone: string;
  reactivationDate?: string;
  selectedClinic?: MeClinic;
  selectedLocation?: number;
  signature?: string;
  profileImage: string | null;
  bio?: string;
  studentRecord?: StudentRecord;
  surveyOptOut: boolean;
  termsCurrent?: boolean;
  title: string;
  macros?: { id: number; shortcut: string; phrase: string }[];
};

/**
 * Protected by closure. Doing this once may not provide any performance
 * benefit, but it is a decent pattern to follow.
 */
let settingValuesByKey: { [key: number]: { [key: string]: any } };

/**
 * If the jsonObject on the setting is not an array, the 'key' in this will
 * be the property names on that object. If it is an array, the key will be
 * the setting name.
 *
 * This provides a common way to retrieve the value of a property inside an
 * extended setting.
 *
 * @param clinic
 * @param name
 * @returns
 */
export const getExtendedSettingValueByKey = (
  clinic: MeClinic | null,
  name: string,
): any => {
  if (!clinic) return null;

  settingValuesByKey = settingValuesByKey || {};
  if (!settingValuesByKey[clinic.ID]) {
    settingValuesByKey[clinic.ID] = (clinic.settings || []).reduce(
      (acc: { [key: string]: any }, obj: MeClinicSettingType) => {
        if (Array.isArray(obj.jsonValue)) {
          acc[obj.setting] = obj.jsonValue;
        } else {
          Object.keys(obj.jsonValue).forEach((key) => {
            acc[key] = obj.jsonValue[key];
          });
        }

        return { ...acc };
      },
      {},
    );
  }

  return settingValuesByKey[clinic.ID][name];
};
